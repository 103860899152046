<template>
  <v-sheet elevation="2" class="pa-5">
    <v-container fluid>
      <v-row v-show="this.loading === true">
        <v-progress-linear
          :value="this.progress"
          class="my-3"
          label="Uploading file"
          height="6"
        ></v-progress-linear>
      </v-row>
      <v-row class="mb-3">
        <v-col lg="12" md="12" sm="12">
          <v-btn
            v-if="value.transaction_allocation_status === 'No'"
            class="float-right"
            small
            text
            outlined
            right
            @click.stop="passAllocateTransaction()"
            ><v-icon small left> mdi-plus</v-icon>Allocate Transaction</v-btn
          >
          <h1>Attachments</h1>
        </v-col>
      </v-row>
      <!--Files-->
      <v-row>
        <v-col lg="12" md="12" sm="12">
          <v-data-table
            :headers="headers"
            :items="value.attachments"
            :items-per-page="value.attachments.length"
            class="elevation-0"
            hide-default-header
            hide-default-footer
          >
            <!--File Link-->
            <template v-slot:item.file_name="{ item }">
              <a :href="item.src" style="text-decoration: none" target="blank">
                <v-icon small left>mdi-file-{{ returnFileType(item) }}</v-icon>
                {{ item.file_name }}
              </a>
            </template>
            <!--Actions-->
            <template v-slot:item.actions="{ item }">
              <v-btn
                small
                icon
                right
                @click="deleteAttachment(item)"
                class="red--text"
                ><v-icon small> mdi-delete</v-icon></v-btn
              >
            </template>
            <!--No Data-->
            <template v-slot:no-data>
              <div @click="$refs.attachment_input.click()">
                <v-alert prominent color="#c5efe0" icon="mdi-upload">
                  There are no files attached to this transaction
                </v-alert>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row v-for="item in value.attachments" v-bind:key="item.src">
        <v-col>
          <!--Preview-->
          <div style="overflow: scroll; height: 400px">
            <img
              v-if="item.file_name.slice(-3) !== 'pdf'"
              :src="item.src"
              style="width: 100%; height: auto; border: none"
            />
            <iframe
              v-else
              style="width: 100%; height: 400px; border: none"
              :src="item.src"
            ></iframe>
          </div>
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col>
          <v-btn
            @click="$refs.attachment_input.click()"
            color="#33cc99"
            class="white--text"
            :loading="loading"
          >
            Upload & Attach New File
            <v-icon small right>mdi-upload</v-icon>
          </v-btn>
          <input
            type="file"
            label="Upload File"
            style="display: none"
            ref="attachment_input"
            @change="uploadAttachment"
            accept="image/*, .pdf"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>
<script>
import db from "../../../components/firebaseInit";
import firebase from "firebase";
import { newDateToISO } from "../../../composables/external";
import { showSnackbar } from "../../../globalActions";
export default {
  name: "TransactionAttachmentsDialog",
  props: ["value"],
  data() {
    return {
      loading: false,
      file: null,
      url: null,
      progress: null,
      created_by_id: null,
      created_by_name: null,
      date: null,
      headers: [
        { text: "File Name", value: "file_name", align: "start" },
        { text: "Actions", value: "actions", align: "right" },
      ],
    };
  },
  created() {
    //  Capture current user
    this.captureUser();
  },
  methods: {
    captureUser() {
      this.created_by_id = firebase.auth().currentUser.uid;
      db.collection("users")
        .where("uid", "==", this.created_by_id)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.created_by_name = doc.data().displayName;
          });
        });
    },
    returnFileType(item) {
      return item.file_name.slice(item.file_name.length - 3) === "pdf"
        ? "pdf"
        : "image";
    },
    uploadAttachment() {
      // Get file from input field
      // Upload file to storage
      this.loading = true;
      this.file = event.target.files[0];
      const storageRef = firebase
        .storage()
        .ref("attachments/" + this.file.name)
        .put(this.file);
      // Get file URL
      storageRef.on(
        "state_changed",
        (snapshot) => {
          // Get upload progress -- number of bytes uploaded and the total number of bytes to be uploaded
          this.progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          return error;
        },
        () => {
          storageRef.snapshot.ref
            .getDownloadURL()
            .then((downloadURL) => {
              this.url = downloadURL;
              console.log(this.url);
            })
            .then(() => {
              if (
                this.value.attachments.filter(
                  (item) => item.file_name === this.file.name
                ).length > 0
              ) {
                this.loading = false;
                alert("This file as already been attached");
                return;
              } else {
                this.addAttachment();
              }
            })
            .catch((error) => error);
        }
      );
    },
    addAttachment() {
      //  Create new attachment object
      const attachment = {
        file_name: this.file.name,
        src: this.url,
        date: newDateToISO(),
        created_by_id: this.created_by_id,
        created_by_name: this.created_by_name,
      };
      //    Restructure attachments array
      const docRef = db
        .collection("bank_transactions")
        .doc(this.value.transaction_id);
      //  Add attachment to array in bank transaction document
      docRef
        .update({
          attachments: firebase.firestore.FieldValue.arrayUnion(attachment),
        })
        .then(() => {
          this.loading = false;
          this.$refs["attachment_input"].value = "";
          showSnackbar(`File attached successfully`);
        })
        .catch((error) => error);
    },
    deleteAttachment(item) {
      //  Delete array item
      const attachmentArray = this.value.attachments;
      const index = attachmentArray.findIndex(
        (element) => element.file_name === item.file_name
      );
      attachmentArray.splice(index, 1);
      const docRef = db
        .collection("bank_transactions")
        .doc(this.value.transaction_id);
      //  Add attachment to array in bank transaction document
      docRef
        .update({
          attachments: attachmentArray,
        })
        .then(() => {
          showSnackbar(`Attachment deleted successfully`);
        })
        .catch((error) => error);
    },
    passAllocateTransaction() {
      this.$emit("passAllocateTransaction");
    },
  },
};
</script>
